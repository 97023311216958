//React
import React, { useState, useEffect, useRef } from "react";

//Context
import { PDFProvider } from "../../context/PDFProvider";

//Components
import FormComponent from "../FormComponent";
import ModalSignature from "../ModalSignature";
import EvolutionPdf from "../pdf/EvolutionPdf";
import TextEditor from "../TextEditor";

//Icons
import {
    FaFileDownload,
    FaFileSignature,
    FaEllipsisH,
    FaEdit,
    FaPrint,
} from "react-icons/fa";

//Library components
import {
    Flex,
    Table,
    Modal,
    Tag,
    Form,
    Button,
    message,
    Drawer,
    Tooltip,
    Dropdown,
    Descriptions,
} from "antd";

import dayjs from "dayjs";

//Api
import axios from "../../api/axios";
import pattern from "../../api/pattern";

//Variables
const { endpoints, forms, localeTable } = pattern;

const PatientEvolution = (props) => {
    const [loadingEvolutions, setLoadingEvolutions] = useState(true);
    const [loadingButtonDrawer, setLoadingButtonDrawer] = useState(false);
    const [idEvolution, setIdEvolution] = useState(null);
    const [dataEvolutions, setDataEvolutions] = useState([]);
    const [dataActiveDentists, setDataActiveDentists] = useState([]);

    const [isModalPrintEvolutionOpen, setIsModalPrintEvolutionOpen] =
        useState(false);
    const [isModalSignOpen, setIsModalSignOpen] = useState(false);

    const [openEvolutions, setOpenEvolutions] = useState(false);
    const [openEvolutionsEdit, setOpenEvolutionsEdit] = useState(false);
    const [formEvolutions] = Form.useForm();
    const [formEvolutionsEdit] = Form.useForm();
    const countRender = useRef(0);

    const fetchDataEvolutions = async () => {
        try {
            const response = await axios.get(
                endpoints.evolutions.index.replace("${}", props.patientId)
            );
            setDataEvolutions(response.data);
            setLoadingEvolutions(false);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };
    const fetchDataActiveDentists = async () => {
        try {
            const response = await axios.get(
                endpoints.users.indexActiveDentists
            );
            setDataActiveDentists(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        // if (countRender.current < 1) {
        //     countRender.current += 1;
        //     return;
        // }
        if (props.isModalOpen === false) {
            return;
        }

        fetchDataActiveDentists();
        fetchDataEvolutions();
    }, [props.isModalOpen]);

    const onClose = () => {
        setOpenEvolutions(false);
        formEvolutions.resetFields();
    };

    const onCloseEdit = () => {
        setOpenEvolutionsEdit(false);
        formEvolutionsEdit.resetFields();
    };

    const showModalSign = () => {
        setIsModalSignOpen(true);
    };
    const handleCancelSign = () => {
        setIsModalSignOpen(false);
    };

    const showModalPrintEvolution = () => {
        setIsModalPrintEvolutionOpen(true);
    };
    const handleCancelPrintEvolution = () => {
        setIsModalPrintEvolutionOpen(false);
    };

    const onFinishEvolution = async (values) => {
        setLoadingButtonDrawer(true);
        const formattedValues = {
            ...values,
            user_id: props.role === "DENTIST" ? props.userId : values.user_id,
            patient_id: props.patientId,
        };

        try {
            await axios.post(endpoints.evolutions.create, formattedValues, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            });

            fetchDataEvolutions();
            setLoadingButtonDrawer(false);
            onClose();

            message.success("Evolução cadastrada com sucesso!");
        } catch (error) {
            console.log(error);
            message.error("Campo(s) inválido(s)!");
        }
    };

    const onFinishEvolutionEdit = async (values) => {
        setLoadingButtonDrawer(true);
        const formattedValues = {
            ...values,
            user_id: props.role === "DENTIST" ? props.userId : values.user_id,
        };
        try {
            await axios.post(
                endpoints.evolutions.update.replace("${}", idEvolution),
                formattedValues,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            fetchDataEvolutions();
            setLoadingButtonDrawer(false);
            onCloseEdit();

            message.success("Evolução atualizada com sucesso!");
        } catch (error) {
            console.log(error);
            message.error("Campo(s) inválido(s)!");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const showDrawerEvolutionEdit = (id) => {
        const evolution = {
            ...dataEvolutions.find((evolution) => evolution.id === id),
        };

        evolution.date = dayjs(evolution.date);
        console.log(evolution);
        formEvolutionsEdit.setFieldsValue(evolution);
        setOpenEvolutionsEdit(true);
    };

    const handleGetEvolution = (id) => {
        const evolution = {
            ...dataEvolutions.find((evolution) => evolution.id === id),
        };

        return evolution;
    };

    const columnsEvolutions = !props.isMobile
        ? [
              {
                  title: "Dentista",
                  dataIndex: "dentistName",
                  key: "dentistName",
                  //width: "100px",
              },
              {
                  title: "Descrição",
                  dataIndex: "description",
                  key: "description",
                  render: (_, { description }) =>
                      description === null ? (
                          "-"
                      ) : (
                          <div
                              style={{ maxWidth: "300px" }}
                              className="textEditorDescription"
                          >
                              <TextEditor readOnly={true} value={description} />
                          </div>
                      ),
              },
              {
                  title: "Data",
                  dataIndex: "dateFormatted",
                  key: "dateFormatted",
                  //width: "100px",
              },
              {
                  title: "Assinado",
                  dataIndex: "signed",
                  key: "signed",
                  render: (_, { sign, signed }) => {
                      return (
                          <Tag color={sign.signed ? "green" : "red"} key={sign}>
                              {signed}
                          </Tag>
                      );
                  },
              },
              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  render: (_, { id, sign }) =>
                      sign.signed ? (
                          <Tooltip title="Documento assinado">
                              <Button
                                  onClick={() => {
                                      props.setLoadingDocSignDownload(
                                          (prev) => ({
                                              ...prev,
                                              [id]: true,
                                          })
                                      );
                                      props.handleDownloadDocumentSigned(
                                          sign.file,
                                          id
                                      );
                                  }}
                                  loading={props.loadingDocSignDownload[id]}
                                  icon={<FaFileDownload />}
                              />
                          </Tooltip>
                      ) : (
                          <Flex gap="small">
                              <Tooltip title="Assinar">
                                  <Button
                                      onClick={() => {
                                          setIdEvolution(id);
                                          showModalSign();
                                      }}
                                      icon={<FaFileSignature />}
                                  />
                              </Tooltip>
                              <Dropdown
                                  menu={{ items: subitemsEvolution(id) }}
                                  placement="bottom"
                                  arrow
                              >
                                  <Button icon={<FaEllipsisH />} />
                              </Dropdown>
                          </Flex>
                      ),
              },
          ]
        : [
              {
                  title: "Descrição",
                  dataIndex: "description",
                  key: "description",
                  render: (_, { description }) =>
                      description === null ? (
                          "-"
                      ) : (
                          <div
                              style={{ maxWidth: "300px" }}
                              className="textEditorDescription"
                          >
                              <TextEditor readOnly={true} value={description} />
                          </div>
                      ),
              },
              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  render: (_, { id, sign }) =>
                      sign.signed ? (
                          <Tooltip title="Documento assinado">
                              <Button
                                  onClick={() => {
                                      props.setLoadingDocSignDownload(
                                          (prev) => ({
                                              ...prev,
                                              [id]: true,
                                          })
                                      );
                                      props.handleDownloadDocumentSigned(
                                          sign.file,
                                          id
                                      );
                                  }}
                                  loading={props.loadingDocSignDownload[id]}
                                  icon={<FaFileDownload />}
                              />
                          </Tooltip>
                      ) : (
                          <Flex gap="small">
                              <Tooltip title="Assinar">
                                  <Button
                                      onClick={() => {
                                          setIdEvolution(id);
                                          showModalSign();
                                      }}
                                      icon={<FaFileSignature />}
                                  />
                              </Tooltip>
                              <Dropdown
                                  menu={{ items: subitemsEvolution(id) }}
                                  placement="bottom"
                                  arrow
                              >
                                  <Button icon={<FaEllipsisH />} />
                              </Dropdown>
                          </Flex>
                      ),
              },
          ];

    const subitemsEvolution = (id) => [
        {
            key: "1",
            label: (
                <Button
                    type="link"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    icon={<FaEdit />}
                    onClick={() => {
                        setIdEvolution(id);
                        showDrawerEvolutionEdit(id);
                    }}
                >
                    Editar
                </Button>
            ),
        },
        {
            key: "2",
            label: (
                <Button
                    type="link"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    icon={<FaPrint />}
                    onClick={() => {
                        setIdEvolution(id);
                        showModalPrintEvolution();
                    }}
                >
                    Imprimir
                </Button>
            ),
        },
    ];

    const expandedRowRender = (record) => {
        const items = !props.isMobile
            ? []
            : [
                  {
                      label: "Dentista",
                      children: record.dentistName,
                  },
                  {
                      label: "Data",
                      children: record.dateFormatted,
                  },
                  {
                      label: "Assinado",
                      children: (
                          <Tag
                              color={record.sign.signed ? "green" : "red"}
                              key={record.sign}
                          >
                              {record.signed}
                          </Tag>
                      ),
                  },
              ];

        return <Descriptions items={items} />;
    };

    return (
        <>
            <Modal
                title={
                    <Flex
                        align="center"
                        justify="space-between"
                        className="modal-header-title-button"
                    >
                        <div className="modalTitle">Evoluções</div>
                        <Button
                            type="primary"
                            onClick={() => {
                                setOpenEvolutions(true);
                            }}
                        >
                            + Cadastrar
                        </Button>
                    </Flex>
                }
                open={props.isModalOpen}
                onCancel={() => props.setIsModalOpen(false)}
                centered={true}
                width={1000}
                footer={(_, {}) => <></>}
            >
                <Table
                    rowKey="id"
                    columns={columnsEvolutions}
                    dataSource={dataEvolutions}
                    locale={localeTable}
                    loading={loadingEvolutions}
                    expandable={
                        !props.isMobile
                            ? false
                            : {
                                  expandedRowRender: expandedRowRender,
                              }
                    }
                    pagination={{
                        defaultPageSize: !props.isMobile ? 5 : 3,
                    }}
                />
            </Modal>

            <Drawer
                title="+ Cadastrar evolução"
                placement="right"
                closable={!props.isMobile ? false : true}
                width={!props.isMobile ? 378 : "100%"}
                onClose={onClose}
                open={openEvolutions}
                key="createEvolution"
            >
                <FormComponent
                    name="createEvolution"
                    form={formEvolutions}
                    onFinish={onFinishEvolution}
                    onFinishFailed={onFinishFailed}
                    formItens={
                        props.role !== "DENTIST"
                            ? forms.evolutions.evolutionsWithDentist
                            : forms.evolutions.evolutionsWithoutDentist
                    }
                    selectSearchProps={{
                        data: {
                            dentists: dataActiveDentists,
                        },
                        endpoint: {
                            dentists: endpoints.users.indexActiveDentists,
                        },
                    }}
                    loading={loadingButtonDrawer}
                    openDrawer={openEvolutions}
                    buttonText="Cadastrar"
                />
            </Drawer>

            <Drawer
                title="Editar evolução"
                placement="right"
                closable={!props.isMobile ? false : true}
                width={!props.isMobile ? 378 : "100%"}
                onClose={onCloseEdit}
                open={openEvolutionsEdit}
                key="editEvolution"
            >
                <FormComponent
                    name="editEvoltuion"
                    form={formEvolutionsEdit}
                    onFinish={onFinishEvolutionEdit}
                    onFinishFailed={onFinishFailed}
                    formItens={forms.evolutions.evolutionsWithDentist}
                    selectSearchProps={{
                        data: {
                            dentists: dataActiveDentists,
                        },
                        endpoint: {
                            dentists: endpoints.users.indexActiveDentists,
                        },
                    }}
                    openDrawer={openEvolutionsEdit}
                    loading={loadingButtonDrawer}
                    buttonText="Salvar alterações"
                />
            </Drawer>

            <Modal
                title="Imprimir"
                open={isModalPrintEvolutionOpen}
                onCancel={handleCancelPrintEvolution}
                centered={true}
                width={700}
                footer={(_, {}) => <></>}
            >
                <PDFProvider>
                    <EvolutionPdf
                        evolution={handleGetEvolution(idEvolution)}
                        patient={props.handleGetPatient(props.patientId)}
                        account={props.account}
                    />
                </PDFProvider>
            </Modal>

            <Modal
                title="Assinatura"
                open={isModalSignOpen}
                onCancel={handleCancelSign}
                centered
                width="100%"
                className="modalDocumentSign"
                footer={(_, {}) => <></>}
            >
                <PDFProvider>
                    <EvolutionPdf
                        evolution={handleGetEvolution(idEvolution)}
                        patient={props.handleGetPatient(props.patientId)}
                        account={props.account}
                        sign={true}
                    />
                    <ModalSignature
                        close={handleCancelSign}
                        fetch={fetchDataEvolutions}
                        entity={{ name: "evolution", id: idEvolution }}
                    />
                </PDFProvider>
            </Modal>
        </>
    );
};

export default PatientEvolution;
